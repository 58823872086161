@import "vars";

.flash.contact {

    .button {
        position: absolute;
        left: 126px; top: 5px;
        display: block;
        padding: 8px 16px;
        
        @include mobile {
            position: relative;
            left: 0;
            top: 0;
        }
    }

}

#pageFooter {

    background-color: $blueBackground;
    @include clearfix();
    color: $white;

    .column {
        box-sizing: border-box;
        float: left;
        padding: 24px;

        h4 {
            font-weight: normal;
            font-size: 26px;
            margin: 0; padding: 0;
        }

        a {
            color: $white;
        }

        ul {
            margin: 16px 0 0 0;
            padding: 0;
            list-style: none;

            li {
                margin: 4px 0 0 0;
                padding: 0;
            }
        }


        input[type=text] {
            float: left;
            width: 76%;
            box-sizing: border-box;
            padding: 6px 12px;
            border: $inputBorder;
        }

        input[type=submit] {
            float: right;
            width: 22%;
            box-sizing: border-box;
            padding: 6px 4px;
            background-color: darken($blueBackground, 20%);
            border: $inputBorder;
            cursor: pointer;
            color: $white;
        }

        input[type=submit]:hover {
            background-color: darken($blueBackground, 30%);
        }
    } // .column
    
    .col1 {
        width: 25%;
    }

    .col2 {
        width: 45%;
    }

    .col3 {
        width: 30%;
    }
    
    .col1, .col2, .col3 {
        @include mobile {
            width: 100%;
        }
    }

    .category-module {
        position: relative;
        @include clearfix();

        li {
            position: relative;
            margin: 0;
            padding: 0;
        }

        .mod-articles-category-thumbnail {
            position: absolute;
            left: 0;
            top: 6px;
            width: 64px;
            height: 50px;

            img {
                max-width: 64px;
                max-height: 50px;

            }
        }

        .mod-articles-category-date, p {
            margin: 0 0 0 76px;
        }

        .mod-articles-category-date {
            a {
                color: $blogLink;
                font-weight: 600;
            }
        }

        p {
            padding: 0 0 12px 0;
        }
    }        
} // #pageFooter