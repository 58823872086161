@import "vars";

#pageContent {

    .splash {
        @include clearfix();
        position: relative;
        font-weight: 600;

        padding: 2%;

        h1 {
            font-size: 18px;
        }
        
        .headline {
            @include clearfix();

            .left {
                float: left;
                width: 30%;
                padding: 2%;
                box-sizing: border-box;
            }

            .right {
                float: right;
                width: 70%;
                padding: 2% 0;
            }
        }
        

        .banner {
            position: relative;

            // apsect ratio trick so that while image is loading there is no
            // pop in
            padding-top: percentage(484 / 1216);
            padding-bottom: 16px;

            img {
                position: absolute;
                top: 0px;
                width: 100%;
            } 
        }

        .description {
            padding: 16px;
            background-color: $overviewBackground;
            p {
                margin-bottom: 0;
            }
        }

        h1 {
            font-weight: 600;
            margin: 0;
            padding: 0;
        }

    }

    .flash {
        text-align: center;
        padding: 24px;
        background-color: $flashBackground;
        font-weight: 600;
        color: $white;

        p {
            padding: 0;
            margin: 0;
        }
    }

    .flash.contact {
        @include clearfix();
        height: 50px;
        
        .button {
            left: 126px; top: 5px;
            display: block;
            padding: 8px 16px;
        }
        
        .page {
            margin: 0 0 0 50%;
        }

        p {
            position: absolute;
            left: -280px;
        }

    }
    
    .bg1 {
        background-color: $bg1;
    }

    .bg2 {
        background-color: $bg2;
    }

    .development {
        height: 300px;
        padding: 48px;
        @include clearfix();

        @include mobile {
            padding: 8% 4%; 
            height: auto;
        }
        
        
        h3 {
            margin: 48px 0 0 0;
            padding: 0;
            font-weight: 600;
            font-size: 20px;
        }
        
        .page {
            margin-left: 50%;
            width: 1px;
            @include mobile {
                margin-left: 0;
                width: auto;
            }
        }

        .left {
            position: absolute;
            left: 24px;
            
            @include mobile {
                float: none;
                position: relative;
                left: 0;
            }
        }

        .right {
            float: right;

            position: absolute;
            right: 24px;
            
            @include mobile {
                float: none;
                right: auto;
                position: relative;
            }
        }

        .image {
            width: 340px; height: 300px;
            
            @include mobile {
                width: 100%;
                height: auto;
                max-width: 400px;
                margin: 0 auto 0 auto;
            }
        }

        .column.text {
            width: 330px;
        }

    } // .development
    
    @include mobile {
        .splash {
            .headline {
                .left {
                    float: none;
                    width: auto;
                }
                .right {
                    float: none;
                    width: auto;
                }
            }
        }
        
        .flash.contact {
            height: auto;
            margin: 0;
            
            .page {
                margin: 0;
            }
            
            p {
                position: relative;
                left: 0;
                top: 0;
            }
            
            .button {
                left: 0;
                top: 0;
                margin: 16px 0 0 0;
                display: block;
                padding: 8px 16px;
            }
        }
        
    } // @mobile
    
} // #pageContent