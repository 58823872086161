@import "_vars";

/* Main Style Sheet */
html, body {
    margin: 0;
    padding: 0;

    width: 100%;
    height: 100%;
    
    background: $defaultBackground;
    
    font-family: "Open Sans", sans serif;
    font-size: 18px;
}

.page {
    max-width: 1200px;
    margin: 0 auto 0 auto;
    box-sizing: border-box;
}

section {
    position: relative;
    display: block;
}

div {
    position: relative;
    display: block;
}

img {
    display: block;
    border-style: none;
}

a {
    color: $link;
    text-decoration: none;
}

a:hover {
    color: $linkHover;
}

p {
    margin: 16px 0;
    padding: 0;
}

input, textarea, button {
    font-family: "Open Sans", sans serif;
    font-size: 18px;
}

.content.error {
    
    .page {
        margin: 32px auto;
        max-width: 800px;
        background-color: $white;
        padding: 32px;
        
        h1 {
            color: $red;
            margin: 0 0 16px 0;
            padding: 0;
            font-size: 32px;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// was ui.less

.button {
    box-sizing: border-box;
    position: relative;
    padding: 12px 24px;
    background-color: $buttonBackground;
    color: $buttonText;
    display: block;
    width: 180px;
    
    @include mobile {
        position: relative;
        display: block;
        width: 100%;
        margin: 0 auto 0 auto;
        clear: both;
    }

    .chevron {
        position: absolute;
        right: 16px; top: 10px;
    }
}

.button:hover {
    background-color: darken($buttonBackground, 10%);
    color: $white;
}

