@import "vars";

$subMenuBackground: lighten($menuBackground, 10%);

#pageMenu, #pageSubMenu {
    background-color: $menuBackground;
    height: 60px;
    font-weight: 600;
    
    .title {
        display: none;
        
        @include mobile {
            display: block;
        }
    } // .title

    .nav {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 26px;
        
        @include mobile {
            font-size: 24px;
        }

        li {
            float: left;
            margin: 0;
            background-color: $menuBackground;
            @include mobile {
                float: none;
            }
        }

        li:first-of-type a {
            padding-left: 16px;
            
            @include mobile {
                padding-left: 32px;
            }
        }

        li:last-of-type a {
            padding-right: 16px;
            
            @include mobile {
                padding-right: 0;
            }
        }

        li a {
            @include transition(all $delay ease-in-out);
            display: block;
            padding: 12px 16px;
            color: $linkMenu;
            
            @include mobile {
                padding: 0 0 0 32px;
            }
        }

        li.active a {
            color: $black;
            font-weight: 700;
        }

        li a:hover {
            color: $white;
            background-color: darken($menuBackground, 10%);
        }

        .parent {
            .nav-child {
                z-index: 100;
                position: absolute;
                padding: 0;
                list-style: none;
                
                @include mobile {
                    position: relative;
                }

                li {
                    max-height: 0px;
                    overflow: hidden;
                    width: 320px;
                    font-size: 22px;
                    float: none;
                    
                    @include mobile {
                        max-height: none;
                        width: auto;
                        font-size: 24px;
                        padding-left: 32px;
                    }

                    @include transition(max-height $delay ease-in-out);

                    a {
                        font-weight: 600;
                    }
                }

            }                 
        }

        .parent:hover {
            .nav-child {
                display: block;

                li {
                    max-height: 60px;
                }

                li:first-of-type {
                }
                li:last-of-type {
                }

            }
        }
    } // .nav
} // #pageMenu, #pageSubMenu

#pageMenu {
    @include mobile {
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        height: 60px;
        width: 100%;
        z-index: 100;
        
        background: $menuBackground url(../images/icons/mobile-menu.png) no-repeat top right;
        
        @include transition(height $delay ease-in-out);
        
        .title {
            padding-left: 16px;
            font-size: 24px;
            line-height: 60px;
        }
        
        // hide menu
        nav {
            font-size: 24px;
            margin-top: 0px;
            padding-bottom: 16px;
            line-height: 40px;
            overflow: hidden;
            height: 0px;
            visibility: hidden;
            
            ul {
                
                margin: 0;
                list-style: none;
                li {
                    a {
                        padding: 0 4px;
                        color: $linkMenu;
                        display: block;
                        @include transition(background-color $delay ease-in-out);
                    }
                    
                    a:hover {
                        background-color: darken($menuBackground, 10%);
                    }
                    
                }
            }
            
        }        
    } // @mobile
} // #pageMenu

#pageMenu.open {
    @include mobile {
        height: auto;
        nav {
            visibility: visible;
            height: auto;
        }
    }  // @mobile
} // #pageMenu.open

#pageSubMenu {
    background-color: $subMenuBackground;
    
    height: 54px;
    .nav {
        font-size: 22px;
        li {
            background-color: $subMenuBackground;
        }
    } // .nav
    
} // #pageSubMenu

#pageSubMenu {
    @include mobile {
        display: none;
    } // @mobile
} // #pageSubMenu

#pageHeader {
    background-color: $orangeBackground;
    @include clearfix();

    .logo {
    }

    .title {
    }

    .social {
        margin: 0; 
        list-style: none;

        li {
            float: left;
        }

        a {
            color: $white;
            line-height: 33px;
        }

        .phone {
            padding-right: 16px;
        }

        .email {
            padding-right: 16px;
        }

        img {
            opacity: 0.8;
            @include transition(opacity $delay ease-in-out);
        }

        img:hover {
            opacity: 1;
        }

        .facebook {
            padding-right: 4px;
        }
    }

    .left {
        float: left;
        @include mobile {
            float: none;
        }
    }

    .logo {
        margin: 32px 0 0 32px;
        position: absolute;
        width: 106px; height: 106px;
    }

    .title {
        margin: 36px 0 24px 138px;
        width: 492px; height: 92px;
    }

    .social {
        font-size: 14px;
        position: absolute;
        right: 16px; top: 16px;
        padding: 0;
    }    
} // #pageHeader

#pageHeader {
    @include mobile {
        .logo {
            float: left;
            width: 14%;
            padding: 72px 0 0 2%;
            margin: 0;
            height: auto;
            position: relative;
        }
        .title {
            float: left;
            width: 82%;
            padding: 72px 2% 0 0;
            margin: 0;
            height: auto;
        }
        
        .mainmenu {
            font-size: 24px;
            clear: both;
        }
        
        .social {
            font-size: 24px;
            padding: 16px;
            overflow: auto;
            clear: both;
            position: relative;
            top: 0;
            right: auto;
            left: 0;
        }
        
    } // @mobile
} // #pageHeader
