// Colors
$defaultBackground: #f0ebe1;
$link: #1081ac;
$linkHover: #1081ac;
$linkMenu: #58291b;
$tooltip: #ffffca;
$errorSaturated: #ead0d0;

$black: #000;
$white: #fff;
$red: #c93b3b;

// header
$orangeBackground: #e96f4b;
$menuBackground: #d96543;

// footer
$blueBackground: #82B1CB;
$inputBorder: rgba(0, 0, 0, 0.2) solid 1px;

// home
$flashBackground: #58585a;
$bg1: $white;
$bg2: #eeeeee;
$buttonBackground: #357394;
$buttonText: $white;
$buttonGreyBackground: #a4a4a9;
$blogLink: #14608a;

// case studies
$caseImageBackground: #072A3D;
$caseLinkBackground: #357394;
$overviewBackground: rgba(255, 255, 255, 0.9);
$serviceBackground: darken($defaultBackground, 5%);

// services
$diagramBackground: #e9a14b;
$lineBackground: #aaaaaa;

// contact us
$contactBackground: #ffd39f;
$contactBorder: #808080;
$contactLabel: #e7e7e7;

// Settings
$delay: 200ms;

// Responsive Mixins
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}
@mixin mobile-and-tablet {
    @media (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}
@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}
@mixin all {
    @media (min-width: 0px) {
        @content;
    }
}

// Mixins
@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

// A response image that scales automatically to match aspect ratio
@mixin aspect-block($width, $height) {
    & {
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: percentage($height / $width) 0 0 0;
        box-sizing: border-box;
        
        img {
            display: block;
            width: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;                
        }
        
    }
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin gradient-vertical($top, $bottom) {
    background: $top; /* Old browsers */
    background: -moz-linear-gradient(top,  $top 0%, $bottom 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top), color-stop(100%,$bottom)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  $top 0%,$bottom 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  $top 0%,$bottom 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  $top 0%,$bottom 100%); /* IE10+ */
    background: linear-gradient(to bottom,  $top 0%,$bottom 100%); /* W3C */
}